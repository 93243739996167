import React from "react";

export const BodyContrato = (props) => {
    return (
    <div id="bodyContrato" className="text-center">
        <div className="text-just">
                <p>Este contrato definirá as condições gerais aplicáveis à sua CONTA DE PAGAMENTO do Valour Bank Instituição de Pagamentos LTDA, inscrita no CNPJ 55.937.301/0001-38, localizado na Rua Eudoro Berlink, 37, Moinhos de Vento, Porto Alegre/RS, CEP 9045-0030, neste contrato qualificado pelo nome fantasia Valour Bank.
        <p></p>
        Cabe esclarecer previamente que a ativação de sua conta de pagamento dependerá da análise e aprovação das informações encaminhadas por você no momento de seu cadastro.
        <p></p>
        ATENÇÃO: A adesão a esse contrato significa que você concorda com a totalidade das regras estabelecidas nesse documento. Para dúvidas e esclarecimentos, os nossos contatos serão por meio do canal de atendimento, através do e-mail sac@valourbank.com.br.
        </p>
        <p></p>
        <p></p>
        <h5>CONDIÇÕES APLICÁVEIS À CONTA</h5>
        <p>Abertura da Conta
        <p></p>
        É condição para a abertura e manutenção da conta que você seja uma pessoa física plenamente capaz.
        <p></p>
        A solicitação da abertura da Conta de pagamento do Valour Bank deve se dar mediante: (i) acesso à Plataforma Digital do Valour Bank; (ii) realização de todos os passos indicados na aludida Plataforma Digital para cadastro junto ao Valour Bank, incluindo, mas não se limitando a: (ii.1) preenchimento de dados cadastrais; (ii.2) envio de documentação cadastral exigida; e (ii.3) emissão de senhas pessoais.
        <p></p>
        O preenchimento dos dados cadastrais inclui o fornecimento das informações abaixo, dentre outras:
        <p></p>
        </p>
        <li>a) nome completo;</li>
        <li>b) nome completo da mãe;</li>
        <li>c) data de nascimento;</li>
        <li>d) número de inscrição no CPF/MF;</li>
        <li>e) endereço residencial; e</li>
        <li>f) número do telefone e código de Discagem Direta a Distância (DDD).</li>
        <p>
        Os dados inseridos durante o aludido cadastro deverão ser completos, com informações precisas, idôneas, verdadeiras e exatas, sendo você o único responsável pelas informações fornecidas, inclusive aquelas concernentes à comprovação de sua renda, faturamento e patrimônio.
        <p></p>
        Você deverá enviar imagens legíveis e claras dos documentos solicitados, que podem ser obtidas por meio da digitalização ou registro fotográfico desses documentos, no formato indicado pelo Valour Bank. Os arquivos incompatíveis ou que apresentem qualidade abaixo da adequada poderão ser rejeitados, a exclusivo critério do Valour Bank.
        <p></p>
        Os documentos que serão digitalizados ou fotografados não podem estar rasgados e/ou apresentar rasuras, cortes, obstruções, bem como elementos (letras, números, fotografias, assinaturas ou outros) apagados, desfocados, e/ou suprimidos de qualquer forma. Além disso, é vedada a apresentação, em qualquer hipótese, de documentos originais adulterados ou cópias de documentos que sejam diferentes dos originais, bem como documentos de identificação que não sejam de sua titularidade.
        <p></p>
        Em observância à regulamentação aplicável, o Valour Bank adota certos procedimentos e controles para verificar e validar a sua identidade, sem necessariamente se limitar às informações fornecidas por você, inclusive mediante confrontação de tais informações com aquelas disponíveis em bancos de dados públicos e privados. Dessa forma, tanto durante o processo de cadastro quanto após a abertura da Conta, o Valour Bank poderá solicitar, a qualquer momento e ao seu critério, informações e documentos complementares que julgar necessários, inclusive para fins de atualização, especialmente informações necessárias para a prevenção das práticas de “lavagem” de dinheiro e financiamento do terrorismo.
        <p></p>
        Caso o Valour Bank verifique que as informações fornecidas estão desatualizadas ou são inexatas, falsas ou incompletas, poderá: (i) não aprovar a contratação de outros produtos ou serviços; (ii) suspender a movimentação da Conta até sua regularização; (iii) encerrar a Conta; e/ou (iv) comunicar às autoridades competentes sobre essa irregularidade, sempre de acordo com a legislação aplicável.
        <p></p>
        Ao solicitar a conta de pagamento, você reconhece e concorda expressamente que a inserção de suas Senhas Pessoais nos campos indicados na Plataforma Digital constitui forma legítima e suficiente para a comprovação da sua identidade e da validade de sua declaração de vontade em contratar os serviços objeto deste Contrato.
        <p></p>
        Uma vez aprovada a criação da Conta de Pagamento, independentemente da efetivação de um primeiro aporte de recursos, fica subentendido que você está de acordo com todos os termos e condições deste Contrato, dos Termos de Uso e da Política de Privacidade, ambos disponíveis em www.Valourbank.com.br.
        </p>
        <h5>Conta de Pagamento Valour Bank</h5>
        <p>
        Sua conta de pagamento tem titularidade exclusiva, podendo ser movimentada a qualquer momento, exclusivamente nos termos deste Contrato.
        <p></p>
        A sua conta do Valour Bank terá um número de identificação que será informado a Você no momento da abertura, bem como uma senha de acesso de caráter confidencial, exclusivo e intransferível, a qual será determinada por você com base nos critérios de segurança impostos pelo Valour Bank no primeiro acesso à sua Página Pessoal.
        <p></p>
        Você poderá consultar todas as movimentações de entrada e saída de recursos ocorridas em sua conta de pagamento do Valour Bank através do endereço www.Valour Bank.com.br e pelo aplicativo do celular cadastrado.
        <p></p>
        Salvo em casos de bloqueios previstos neste Contrato ou na legislação vigente aplicável, você poderá retirar a qualquer momento recursos depositados na sua conta do Valour Bank apenas por canais eletrônicos, observados os prazos para disponibilização desses recursos. Caso seja portador de um Cartão que esteja atrelado à movimentação de sua conta do Valour Bank, você poderá realizar retirada de recursos por meio de saques nos termos previstos neste Contrato.
        <p></p>
        O Valour Bank poderá não autorizar movimentações financeiras nos casos em que haja qualquer suspeita de inconsistências cadastrais, crimes financeiros, operações fora do seu padrão de uso e/ou utilização indevida que desrespeite qualquer condição presente neste Contrato, em outras contratações realizadas com Valour Bank, e/ou na legislação vigente aplicável. Nesses casos, as transações serão liberadas após o esclarecimento e regularização da situação que motivou o bloqueio.
        </p>
        <h5>Funcionalidades da conta do Valour Bank</h5>
        <p>
        Uma vez que os recursos estejam depositados na sua conta do Valour Bank, você poderá utilizá-los para realizar transferências, pagar contas e realizar compras. A movimentação dos recursos da sua conta do Valour Bank poderá ocorrer para pessoas físicas e/ou jurídicas localizadas no Brasil e depende da existência prévia de recursos disponíveis.
        <p></p>
        As transferências poderão ser realizadas, nos termos e mediante a utilização das ferramentas de segurança estabelecidas neste Contrato, entre contas do Valour Bank, ou entre contas do Valour Bank e contas de pessoas físicas ou jurídicas em outras instituições financeiras, por meio de canais eletrônicos. Não será possível realizar depósito em espécie. Depósitos com cheques dependerão de prévia autorização do Valour Bank após análise da solicitação do correntista.
        <p></p>
        Caso seja portador de um Cartão na Função Débito, poderá utilizá-lo para realizar movimentações em sua conta do Valour Bank nos termos deste Contrato.
        <p></p>
        Com os recursos depositados na sua conta do Valour Bank, você eventualmente também poderá pagar boletos, contas de serviços públicos, realizar recargas de créditos no seu celular e transferências por meio de plataformas e arranjos de terceiros. Você deverá sempre observar os termos e condições disponibilizados pelos terceiros com os quais o Valour Bank tem parceria para a oferta da facilitação de pagamento. Além disso, esse serviço pode estar indisponível para algumas transações. A validação dos pagamentos de boletos e contas de serviços estará disponível por canais eletrônicos.
        </p>
        <h5>Cartão da conta do Valour Bank</h5>
        <p>
        Se você for detentor de uma conta do Valour Bank, o Valour Bank poderá oferecer a você um Cartão para movimentação da sua conta Valour Bank na Função Débito nos termos deste Contrato, ficando a respectiva habilitação do Cartão condicionada à sua expressa solicitação ou autorização.
        <p></p>
        A utilização do Cartão deve observar a existência prévia de saldo na sua conta do Valour Bank, bem como o período de validade impresso no Cartão. Não obstante, o Valour Bank, visando a sua segurança e para evitar o uso fraudulento do Cartão, poderá eventualmente limitar ou restringir o uso do seu Cartão.
        <p></p>
        A utilização do Cartão é formalizada com a digitação do seu PIN (senha de 4 dígitos cadastrada, pessoal e intransferível que deve ser mantida em sigilo por você) ou, conforme o caso, por outros meios que caracterizam a sua expressa manifestação de vontade e concordância com a operação em questão, incluindo sem limitar pagamentos realizados por meio da tecnologia “contactless”.
        <p></p>
        Você deverá conferir todas as despesas lançadas no seu aplicativo. Caso discorde de algum lançamento, você poderá questioná-lo, no prazo de até 90 dias, contados da data do processamento da transação. As transações contestadas estão sujeitas às regras de resolução de disputa instituídas pela bandeira do seu Cartão.
        <p></p>
        Será permitido realizar saques no território nacional em redes credenciadas por meio do seu Cartão. A autorização de saque está sujeita às medidas de segurança previstas neste Contrato.
        <p></p>
        Poderão ser aplicados limites de saques (valores e frequência) de acordo com as regras de segurança do Valour Bank e das redes credenciadas e não será possível a retirada de recursos (saque) além do saldo previamente aportado e disponível em sua conta do Valour Bank.
        <p></p>
        O seu Cartão também poderá ser utilizado no Brasil para compras através de e-commerce, sendo a transação autorizada mediante existência de saldo, com débito imediato e integral da quantia na sua conta de pagamento.
        </p>
        <h5>Disposições Gerais</h5>
        <p>
        Você declara que todas as informações fornecidas no momento da abertura da conta do Valour Bank e de sua ativação são verídicas, especialmente aquelas concernentes aos países onde você detém residência fiscal, além do Brasil. Você manterá o Valour Bank sempre informado a respeito de quaisquer alterações nos seus dados cadastrais, inclusive na ocorrência da obtenção de cidadania em outros países. Será de sua inteira responsabilidade todas as consequências decorrentes do descumprimento dessa obrigação. Além disso, o Valour Bank discricionariamente poderá solicitar a atualização de seus dados sempre que entender necessário ou quando a legislação vigente aplicável assim exigir. O Valour Bank poderá realizar o bloqueio temporário da sua conta do Valour Bank caso entenda que não houve o cumprimento das obrigações previstas nesta cláusula.
        <p></p>
        Em caso de atraso, Você autoriza o Valour Bank a realizar o débito em conta, total ou parcial, para fins de pagamento de valores devidos por Você ao Valour Bank.
        <p></p>
        Nos termos da Política de Privacidade, o Valour Bank poderá contatá-lo por qualquer meio, inclusive telefônico, e-mail, SMS, WhatsApp e correspondência, para enviar comunicações a respeito da conta do Valour Bank e outras finalidades. O Valour Bank poderá, ainda, enviar mensagens via SMS, WhatsApp, malas diretas, e-mails e propostas referentes a oferta de produtos ou serviços do Valour Bank. Você poderá cancelar, a qualquer momento, o recebimento de notificações não obrigatórias.
        <p></p>
        O Valour Bank comunicará ao Banco Central do Brasil, ao Conselho de Controle de Atividades Financeiras ou outros órgãos que a legislação previr, as operações que possam estar configuradas na Lei 9.613/98 (que dispõe sobre os crimes de lavagem ou ocultação de bens, direitos e valores) e demais disposições legais pertinentes à matéria.
        <p></p>
        Fica eleito o Foro Central da Comarca da Capital do Estado de Porto Alegre/RS para dirimir quaisquer questões relativas ao presente Contrato, sem prejuízo de o titular da conta do Valour Bank optar pelo foro de seu domicílio.
        <p></p>
        Para solução de eventuais conflitos relacionados a este Contrato, para pedidos de cancelamento, reclamações e sugestões, Você poderá entrar em contato com o Valour Bank através do e-mail sac@valourbank.com.br. O Valour Bank coloca ainda a sua disposição o “Fale com a gente” através de formulário no site.
        <p></p>
        O presente Contrato está integralmente disponível para consulta no site do Valour Bank no endereço eletrônico.
        </p>
        <h5>E POR ESTAREM ASSIM, JUSTAS E CONTRATADAS, AS PARTES FIRMAM O PRESENTE CONTRATO PARA TODOS OS FINS DE DIREITO, MANIFESTANDO EXPRESSAMENTE SUA CONCORDÂNCIA COM OS TERMOS, CONDIÇÕES, DIREITOS E OBRIGAÇÕES DISPOSTOS NESTE CONTRATO:</h5>
        <li>(A) □ CONCORDO expressamente com o inteiro teor do presente Contrato, ficando desde já obrigado e sujeito aos direitos e obrigações oriundos deste Contrato.</li>
        <li>(B) □ DECLARO serem inteiramente verdadeiras as informações pessoais fornecidas ao Valour Bank para fins do Cadastro e por meio de minha Página Pessoal e estar ciente que estas informações condicionaram a celebração deste Contrato.</li>
        <p></p>
        <p>Valour Bank Instituição de Pagamentos LTDA</p>
        <p></p>
        <p>CNPJ 55.937.301/0001-38</p>
        </div>
    </div>
    );
};
