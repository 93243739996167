import React from "react";


export const Navigation = (props) => {

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="/">
            <img src="img/logo.png" className="styled-logo"/>
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right ul-limited">
            <li>
              <a href="/#about" className="page-scroll">
                Sobre nós
              </a>
            </li>
            <li>
              <a href="/#services" className="page-scroll">
                para sua empresa
              </a>
            </li>
            <li>
              <a href="/#services" className="page-scroll">
                para você
              </a>
            </li>
            <li className="mr-2">
              <button className="btn btn-custom btn-outlined btn-lg page-scroll" onClick={() => openInNewTab("https://app.valourbank.com.br/login")}>
                 Acessar conta
              </button>
            </li>
            <li>
              <button className="btn btn-custom btn-lg page-scroll" onClick={() => openInNewTab("https://app.valourbank.com.br/register")}>
                Abrir conta
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
