import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row d-flex">
        <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Conheça a Valour Bank Experience</h2>
              <p className="paragraph">{props.data ? props.data.paragraph : "loading..."}</p>
              <p className="paragraph pd">{props.data ? props.data.paragraph2 : "loading..."}</p>
              <h3>Porque nos escolher? Por que Somos!</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>         
        </div>
            <div className="col-xs-12 col-md-6 d-flex">
              {" "}
              <img src="img/bout.png" className="img-responsive" alt="" />{" "}
            </div>
      </div>
      </div>
    </div>
  );
};
